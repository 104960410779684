import React from 'react';
import PropTypes from 'prop-types';

import AutosizeTextarea from '../autosize_textarea';
import Duration from '../duration';
import inputStyles from '../../../../styles/global_ui/inputs.css';

// TODO: Migrate all the helpers here to FormGroup!
const createTextAreaView = (props) => (
  <AutosizeTextarea
    autoFocus={props.autoFocus}
    className={`${inputStyles.textarea} ${inputStyles.textareaAutosize} ${props.classList.textarea} ${props.errors ? inputStyles.inputError : ''}`}
    disabled={props.disabled}
    id={getId(props)}
    name={props.name}
    onChange={props.onChange}
    placeholder={props.placeholder}
    rows="3"
    type="text"
    value={props.value}
  />
);

const createInputView = (props) => (
  <input
    autoFocus={props.autoFocus}
    className={`${inputStyles.input} ${props.classList.input ? props.classList.input : ''} ${props.errors ? inputStyles.inputError : ''}`}
    disabled={props.disabled}
    id={getId(props)}
    name={props.name}
    onChange={props.onChange}
    placeholder={props.placeholder}
    type={props.type}
    value={props.value}
  />
);

const getCharCountView = (charCount, value, maxVal = 'You forgot to pass a maxVal prop!') => {
  if (typeof charCount !== 'number' && !charCount) return null;
  if (typeof charCount === 'function') return charCount({ value, maxVal });

  return (
    <div className={`${inputStyles.charCount} ${value.length > maxVal ? inputStyles.error : ''}`}>
      {`${value.length}/${maxVal}`}
    </div>
  );
};

const getElementForProp = (props) => {
  switch (props.element) {
    case 'textarea':
      return createTextAreaView(props);

    case 'duration':
      return (<Duration {...props} />);

    default:
      return createInputView(props);
  }
};

const getErrorsView = (errors, errorsView, classList) => {
  if (!errors) return null;
  if (typeof errorsView === 'function') return errorsView({ errors });

  return (
    <div className={`${inputStyles.error} ${classList.error}`}>{errors}</div>
  );
};

const getId = ({ id, label, name }) => {
  if (id) return id;
  const base = [label, name].filter((v) => v && v !== '').join('_');

  return base.toLowerCase().replace(/[^a-z0-9]/g, '_');
};

const getMsgView = ({ classList, element, errors, errorsView, charCount, helperText, maxVal, msgView, value, warningText }) => {
  if (typeof msgView === 'function') return msgView({ errors, value, maxVal });

  return (
    <div className={inputStyles.msgWrapper}>
      <div>
        {getErrorsView(errors, errorsView, classList)}
        {helperText && (
          <div className={`${inputStyles.help} ${classList.help}`}>
            {helperText}
          </div>
        )}
        {warningText && (
          <div className={inputStyles.warning}>{warningText}</div>
        )}
      </div>
      {getCharCountView(charCount, value, maxVal)}
    </div>
  );
};

/**
 * Component
 */
const BasicFormInput = (props) => (
  <div className={`${inputStyles.inputWrapper} ${props.classList.root}`}>
    {props.label && <label className={`${inputStyles.label} ${props.classList.label ? props.classList.label : ''}`} htmlFor={getId(props)}>{props.label}</label>}
    {getElementForProp(props)}
    {getMsgView(props)}
  </div>
);

BasicFormInput.propTypes = {
  autoFocus: PropTypes.bool,
  charCount: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  classList: PropTypes.shape({
    root: PropTypes.string,
    help: PropTypes.string,
    input: PropTypes.string,
    label: PropTypes.string,
    textarea: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  element: PropTypes.string,
  errors: PropTypes.string,
  errorsView: PropTypes.func,
  helperText: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  maxVal: PropTypes.number,
  msgView: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  warningText: PropTypes.string,
};

BasicFormInput.defaultProps = {
  autoFocus: false,
  charCount: null,
  classList: {
    root: '',
    help: '',
    input: '',
    label: '',
    textarea: '',
  },
  disabled: false,
  element: 'input',
  errors: null,
  errorsView: null,
  helperText: null,
  id: null,
  label: null,
  maxVal: null,
  name: null,
  msgView: null,
  placeholder: '',
  type: 'text',
  warningText: null,
};

export default BasicFormInput;
