import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import BasicFormInput from '../../../client/form_components/inputs/basic_form_input';
import Button from '../../../client/buttons/base';
import Dialog from '../../../client/reusable_components/Dialog/index';
import FormSelect from '../../../client/form_components/selects/form_select';
import FormCountrySelect from '../../../client/form_components/selects/form_country_select';
import LogsTable from '../../../client/reusable_components/LogsTable';

import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { isRequired } from '../../../services/validation/validators';
import errorHandler from '../../../services/error_handler';
import { graphMutate, graphQuery } from '../../../requests/graphql';
import { getInObj } from '../../../utility/accessors';
import { firstCharUpperCase } from '../../../utility/formatters';
import { isBlank } from '../../../utility/types';
import { getCountryNameFromCountryCode } from '../../../services/country_data';
import { capitalize } from '../../../utility/formatters';
import { DATE_AT_TIME_NUMERIC_TZ, timestampToPrettyDate } from '../../../utility/time';
import { isVerified } from '../../../utility/user_helpers';

import { MEMBERSHIP_ENUM_MAP } from './constants';

import inputs from '../../../styles/global_ui/inputs.css';
import layout from '../../../styles/global_ui/layout.css';
import forms from '../../../styles/global_ui/forms.css';
import typography from '../../../styles/global_ui/typography.css';

const EMPTY_DIALOG_COPY = { body: () => {}, title: '' };

const LOG_CELLS = {
  created_at: { size: 'normal', formatter: (val) => timestampToPrettyDate(val, DATE_AT_TIME_NUMERIC_TZ) },
  process: { size: 'normal', formatter: (val) => capitalize(val) },
  assigner: { size: 'normal', formatter: (val) => capitalize(val) },
  changes: { size: 'large', dataView: true, dataSource: 'tier_data', formatter: (val) => val.sort(), dataFormatters: { citizenship: (val) => getCountryNameFromCountryCode(val), country_of_residence: (val) => getCountryNameFromCountryCode(val) } },
  tier_data: { size: 'large', dataView: true, dataSource: 'tier_data', formatter: (val) => Object.keys(val).sort(), dataFormatters: { citizenship: (val) => getCountryNameFromCountryCode(val), country_of_residence: (val) => getCountryNameFromCountryCode(val) } },
};

const LOG_HEADERS = {
  CREATED_AT: { size: 'normal', title: 'Time changed' },
  ACTION: { size: 'normal', title: 'Assigner' },
  PROCESS: { size: 'normal', title: 'Process' },
  CHANGED: { size: 'large', title: 'Changed' },
  DATA: { size: 'large', title: 'Data' },
};

const OPTIONAL_FIELD_LABELS = {
  affidavit: 'Signed affidavit link',
  country: 'Country of residence',
  citizenship: 'Citizenship',
};

export const c = {
  container: `${layout.container} ${layout.flexColumn} ${layout.flexCenterItems}`,
  outerWrapper: `${layout.wrapper1170} ${layout.flexColumn} ${layout.flexCenterItems}`,
  wrapper: `${forms.panel} ${forms.panelInner} ${layout.flexColumn} ${layout.flexCenterItems} ${layout.paddingTop30} ${layout.marginBottom45}`,
  form: `${forms.container675}`,
  header: `${layout.flexJustifySpaceBetween} ${layout.marginBottom30}`,
};

// Note: Reorders headers to match LOG_HEADERS order, pulls out unncessary keys, and restructures process/changes.
const normalizeLogRowData = (field) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { log_data, created_at, assigner, tier_data, id, user_id } = field;
  return {
    created_at,
    assigner,
    process: log_data.process,
    changes: log_data.changes,
    tier_data,
  };
};

const infoIsRequiredForTier = (tier, field) => {
  if (!isVerified(tier)) return null;
  return isRequired(field);
};

const AdminUserMembershipDashboard = ({
  affidavit,
  citizenship,
  countryOfResidence,
  tier,
  tierOpts,
  userName,
  userId,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedAffidavit, setSelectedAffidavit] = useState(affidavit);
  const [selectedCitizenship, setSelectedCitizenship] = useState(citizenship);
  const [selectedCountry, setSelectedCountry] = useState(countryOfResidence);
  const [selectedTier, setSelectedTier] = useState(tier);
  // const [selectedUserName, setSelectedUserName] = useState(userName); // TODO: Use when username search is added.

  const validateFields = useCallback(() => {
    const formErrors = [
      { name: 'affidavit', value: selectedAffidavit, validFn: (val, tier) => infoIsRequiredForTier(tier, val) }, // TODO: If we confirm this should be a certain URL type, add a regex validation.
      { name: 'citizenship', value: selectedCitizenship, validFn: (val, tier) => infoIsRequiredForTier(tier, val) },
      { name: 'country', value: selectedCountry, validFn: (val, tier) => infoIsRequiredForTier(tier, val) },
      { name: 'tier', value: selectedTier, validFn: (val, tier) => isRequired(val) },
    ].reduce((acc, { name, value, validFn }) => {
      const res = validFn(value, selectedTier);

      if (res) acc[name] = res;
      return acc;
    }, {});

    setErrors(formErrors);
    return isBlank(formErrors);
  }, [selectedAffidavit, selectedCitizenship, selectedCountry, selectedTier]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateFields()) return;
    setIsSubmitting(true);

    const userInfo = {
      affidavit: selectedAffidavit,
      citizenship: selectedCitizenship,
      country_of_residence: selectedCountry,
      tier: MEMBERSHIP_ENUM_MAP[selectedTier],
      user_id: userId,
    };

    return graphMutate({ t: 'update_user_membership_tier' }, userInfo)
      .then(() => {
        summonGlobalMessenger({ msg: 'Saved changes.', type: 'success' });
      })
      .catch((err) => {
        summonGlobalMessenger({ msg: 'Sorry, there was an error saving.', type: 'error' });
        errorHandler('UserMembershipDashboard handleSubmit Error: ', err);
      })
      .finally(setIsSubmitting(false));
  };

  // Note: Tacks on (optional) to fields when tier is 'basic'.
  const fieldLabels = useMemo(() => Object.keys(OPTIONAL_FIELD_LABELS).reduce((acc, field) => {
    const text = OPTIONAL_FIELD_LABELS[field];
    const optional = !isVerified(selectedTier);

    acc[field] = optional ? `${text} (optional)` : text;
    return acc;
  }, {}), [selectedTier]);

  const handleHistoryClick = (e) => {
    e.preventDefault();
    setDialogOpen(true);
  };

  // Note: Clears current field error and sets new value.
  const setValClearErrsForField = (field, val, setFn) => {
    setErrors((prevErrors) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [field]: deleted, ...newErrors } = prevErrors;
      return newErrors;
    });

    setFn(val);
  };

  const getPaginatedTierLogs = (paginationArgs) => new Promise((resolve, reject) => graphQuery({ t: 'get_tier_logs' }, { ...paginationArgs, user_id: userId })
    .then(({ tier_logs }) => resolve(tier_logs))
    .catch((err) => {
      reject(err);
    }));

  const getDialogCopy = () => {
    if (!dialogOpen) return EMPTY_DIALOG_COPY;

    return {
      body: () => <LogsTable cellBuilder={LOG_CELLS} fetchLogs={getPaginatedTierLogs} headers={LOG_HEADERS} logRowNormalizerFn={normalizeLogRowData} />,
      title: `${userName} Membership History`,
    };
  };

  const dialogCopy = getDialogCopy();

  return (
    <main className={c.container}>
      <div className={c.outerWrapper}>
        <div className={c.wrapper}>
          <form className={c.form}>
            <div>
              <div className={c.header}>
                <h1 className={typography.h1}>Membership dashboard</h1>
                <Button
                  disabled={isSubmitting}
                  onClick={handleHistoryClick}
                >
                  View history
                </Button>
              </div>
              <div className={layout.marginBottom30}>
                <label className={`${inputs.label} ${layout.margin0}`}>User</label>
                <div>{userName}</div>
              </div>
            </div>
            <FormSelect
              disabled={isSubmitting}
              errors={errors?.tier}
              label="Membership tier level"
              onSelectedChange={(tier) => setValClearErrsForField('tier', getInObj(['value'], tier), setSelectedTier)}
              options={tierOpts.map((tier) => ({ label: firstCharUpperCase(tier), value: tier }))}
              value={selectedTier}
            />
            <BasicFormInput
              disabled={isSubmitting}
              errors={errors?.affidavit}
              helperText="Jotform link. Make sure any visibility permissions are set to Hackster only."
              label={fieldLabels.affidavit}
              name="affidavit"
              onChange={(e) => setValClearErrsForField('affidavit', e.target.value, setSelectedAffidavit)}
              placeholder="Enter link to signed affidavit."
              value={selectedAffidavit || ''}
            />
            <FormCountrySelect
              countryCode={selectedCountry}
              disabled={isSubmitting}
              errors={errors?.country}
              helperText="Current country the user resides in. Must match info on signed affidavit above."
              label={fieldLabels.country}
              name="country of residence"
              onChange={(countryCode) => setValClearErrsForField('country', countryCode, setSelectedCountry)}
              placeholder="Search for the user's current country of residence."
            />
            <FormCountrySelect
              countryCode={selectedCitizenship}
              disabled={isSubmitting}
              errors={errors?.citizenship}
              helperText="If multiple citizenships, select the most restrictive country. Must match info on signed affidavit above."
              label={fieldLabels.citizenship}
              name="citizenship"
              onChange={(countryCode) => setValClearErrsForField('citizenship', countryCode, setSelectedCitizenship)}
              placeholder="Select the user's citizenship (country)."
            />

            <div className={`${layout.flexJustifyEnd} ${layout.paddingTop30}`}>
              <Button
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        bodyClassName={layout.flexJustifyCenter}
        dismiss={() => setDialogOpen(false)}
        open={dialogOpen}
        title={<h2 className={`${typography.h2} ${typography.textCenter} ${layout.marginBottom30}`}>{dialogCopy.title}</h2>}
        wrapperStyle={{ width: '90%' }}
      >
        {dialogCopy.body()}
      </Dialog>
    </main>
  );
};

AdminUserMembershipDashboard.propTypes = {
  affidavit: PropTypes.string,
  citizenship: PropTypes.string,
  countryOfResidence: PropTypes.string,
  tier: PropTypes.string,
  tierOpts: PropTypes.array,
  userId: PropTypes.number,
  userName: PropTypes.string,
};

export default AdminUserMembershipDashboard;
