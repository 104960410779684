import {
  isEmail as validatorEmail,
  isDecimal as validatorDecimal,
  isCreditCard as validatorCreditCard,
  isURL as validatorIsUrl,
} from 'validator';

import {
  isDatetimeLocalValueValid as isDatetimeLocalValueValidHelper,
  isDatetimeLocalValueInFuture as isDatetimeLocalValueInFutureHelper,
  isDatetimeLocalValueInPast as isDatetimeLocalValueInPastHelper,
} from '../../utility/time';

import { isImageMIMEType, isGif } from '../../utility/images';

import { VIDEO_EMBED_REGEXS } from '../../utility/video';

// Valid embeds for Leaderboard iframe.
export const LEADERBOARD_EMBED_REGEXS = { airtable: /^https:\/\/airtable.com\/embed\/\S/ };

export const INVALID_CHARS_REGEX = { urls: /(http:|https:|www.|href=|mailto:)/ };

export function agreeTo(value, valParser, msg) {
  const parser = valParser ? valParser : (v) => v;
  const val = parser(value);

  return val === true ? null : (msg || 'You must agree to these terms.');
}

export function containsNoLinks(value) {
  const errorMsg = 'Enter a valid text only, cannot contain website, links, or email addresses. Profiles used for SEO or backlinking purposed will be removed.';
  if (!value) return null;

  const keys = Object.keys(INVALID_CHARS_REGEX);

  const isValid = !keys.some((key) => value.match(INVALID_CHARS_REGEX[key]),
  );

  return isValid ? null : errorMsg;
}

// Used for ButtonCropper forms.
export function imageV(image) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return ['id', 'url'].every((key) => image && image.hasOwnProperty(key))
    ? null
    : 'A valid image is required.';
}

export function isEmail(value, msg) {
  return (value && value.length && !validatorEmail(value))
    ? (msg || 'Is not a valid email address.')
    : null;
}

export function isCreditCard(value) {
  return validatorCreditCard(value) ? null : 'Please enter a valid credit card.';
}

export function isCVV(value) {
  const target = value.toString();

  return target.length === 3 && validatorDecimal(target) ? null : 'Must be a three digit number.';
}

export function isDatetimeLocalValueValid(value) {
  if (isDatetimeLocalValueValidHelper(value)) return null;

  return 'Please enter a valid date and time';
}

export function isDatetimeLocalValueInFuture(value) {
  return (value && value.length && !isDatetimeLocalValueInFutureHelper(value))
    ? 'Date must be in the future'
    : null;
}

export function isDatetimeLocalValueInPast(value) {
  if (isDatetimeLocalValueInPastHelper(value)) return null;

  return 'Date must be in the past';
}

export function isDecimal(value) {
  if (!value.length) return null;

  return validatorDecimal(String(value)) ? null : 'Please use only numbers, one decimal and no spaces in this field.';
}

export function isDraftsterEmpty(editorContent) {
  // NOTE: This only checks for a blank state. If there are a bunch of empty lines, this wont catch.
  // If we want a stricter validator we need to convert this content to our model (convertToJSONModel) and check for block level content.
  if (editorContent.every((block) => block.type === 'CE' && block.html === '<p></p>')) {
    return 'Please complete this required field.';
  }

  return null;
}

export function isFullYear(value) {
  const target = value.toString();

  return target.length === 4 && validatorDecimal(target) ? null : 'Invalid year.';
}

export function isInt(value, msg = 'Please use numbers only (no decimal).') {
  if (typeof value === 'number') {
    return Number.isInteger(value) ? null : msg;
  } else if (typeof value === 'string') {
    return (/^\d+$/).test(value) ? null : msg;
  }

  return msg;
}

export function isRequired(value, msg = 'Please complete this required field.') {
  if (value === null) return msg;

  if (typeof value === 'string') {
    return value.length > 0 && value !== 'NaN' ? null : msg;
  } else if (typeof value === 'number') {
    return value && value !== 'NaN' ? null : msg;
  }

  return value && String(value).length && value !== 'NaN' ? null : msg;
}

export function isUrl(value) {
  return validatorIsUrl(value) ? null : 'Enter a valid url.';
}

export function isUrlIfDefined(value) {
  // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
  // eslint-disable-next-line @stylistic/no-mixed-operators
  if (!value || value && !value.length) return null;

  return isUrl(value);
}

export function isUrlWithProtocol(value) {
  return validatorIsUrl(value, { require_protocol: true }) ? null : 'Enter a valid url, http or https is required.';
}

export function isValidEmbedUrl(value) {
  const keys = Object.keys(VIDEO_EMBED_REGEXS);

  const isValid = keys.reduce((acc, key) => {
    if (acc) return acc;
    const match = value.match(VIDEO_EMBED_REGEXS[key]);

    return !!match;
  }, false);

  return isValid ? null : 'Enter a valid youtube or facebook video url.';
}

export function isValidLeaderboardUrl(value) {
  const errorMsg = 'Enter a valid airtable embed url. Must start with https://';
  if (!value) return null;

  const keys = Object.keys(LEADERBOARD_EMBED_REGEXS);

  const isValid = keys.some((key) => value.match(LEADERBOARD_EMBED_REGEXS[key]));

  return isValid ? null : errorMsg;
}

export function isValidImageFile(value, allowGifs = true) {
  if (!value || !isImageMIMEType(value.type)) return "Sorry, that's not a valid image.";
  if (!allowGifs && isGif(value.type)) return 'Sorry, GIFs are not allowed';

  return null;
}

export function maxLength(length, value, msg) {
  const entity = typeof value === 'string' ? 'character' : 'item';

  return (value.length && value.length > length)
    ? (msg || `Maximum ${length} ${entity}s, ${entity} count (${value.length}).`)
    : null;
}

export function minLength(length, value) {
  return (value.length && value.length < length)
    ? `Must be at least ${length} characters.`
    : null;
}

export function minValue(min, value) {
  return (value !== null && parseInt(value) < min)
    ? `Must be at least ${min}.`
    : null;
}

export function objectKeysAllRequired(obj = {}) {
  return obj && Object.keys(obj).reduce((acc, key) => acc ? acc : isRequired(obj[key]), null);
}

export function optionIsRequired(value, msg = null) {
  return value && String(value).length && value !== 'NaN' ? null : (msg || 'Please choose one option.');
}
